@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('/assets/fonts/Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold', sans-serif;
    src: url('/assets/fonts/Montserrat-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Italic', sans-serif;
    src: url('/assets/fonts/Montserrat-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat-BoldItalic', sans-serif;
    src: url('/assets/fonts/Montserrat-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
}

* {
    font-family: 'Montserrat', sans-serif;
}
